import React, { Component } from "react"
import { connect } from "react-redux"
import { GetParameter, Toast, ToastTypes, Loading } from "../components/global/Utils"
import { faEdit, faPlus, faSave, faTrash, faCopy } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import McdTable from "../components/global/Table"
import { Button, Modal, ModalFooter, ModalHeader, ModalBody, Row, Col, Input, Label } from 'reactstrap'
import { validate as uuidValidate } from 'uuid'
import JSONInput from "react-json-editor-ajrm"
import locale from "react-json-editor-ajrm/locale/en"

class Users extends Component {
    constructor(props) {
        super(props)

        this.state = {
            table: {
                title: "",
                columns: { title: [], width: [], align: [], search: [] },
                rows: [],
                pagination: true,
                actions: []
            },
            users: [],
            profiles: [],
            userIdTypes: [
                { key: 1, value: "Utilizador" }, { key: 2, value: "Gestão de Acessos" }, { key: 3, value: "Centro de Custo" },
                { key: 4, value: "Todos Restaurantes" }, { key: 5, value: "Todos Escritório" }],
            modal: {
                isOpen: false, isNew: true, userId: "", profileId: "", userIdType: 1, data: { data: [] }, jsonError: false, lockUserId: false
            },
            Environment: 1,
            usersNames: [],
            modalCopyEnv: { isOpen: false, from: null, to: null }
        }

        this.handleChange = this.handleChange.bind(this)
        this.handleSaveUser = this.handleSaveUser.bind(this)
        this.handleDeleteUser = this.handleDeleteUser.bind(this)
        this.handleChangeEnvironment = this.handleChangeEnvironment.bind(this)
        this.handleCopyUsers = this.handleCopyUsers.bind(this)
        this.handlePasteUsers = this.handlePasteUsers.bind(this)
    }

    componentDidMount() {
        this.getUsers()
    }

    getUsers(env) {

        if (env == null)
            env = this.state.Environment

        let appId = GetParameter("id")
        if (appId == null || this.props.access_token == null) {
            this.props.history.replace("/h1f2d1i1d2b")
            return;
        }

        const requestOptions = {
            method: "GET",
            headers: { authorization: "bearer " + this.props.access_token, "Content-Type": "application/json" }
        }

        this.props.dispatch(Loading(true))

        Promise.all([fetch("/h1f2d1i1d2b/admin/profiles/" + appId + "/" + env, requestOptions)])
            .then(allResponses => {
                if (allResponses[0].status === 200) {
                    Promise.all([allResponses[0].json()])
                        .then(allJson => {

                            fetch("/h1f2d1i1d2b/admin/users/" + appId + "/" + env, requestOptions)
                                .then(response => { if (response.status === 200) return response.json(); throw Error(response.statusText); })
                                .then(json => {
                                    this.setState({
                                        profiles: allJson[0].profiles,
                                        users: json,
                                        table: {
                                            title: allJson[0].appName + " - Utilizadores",
                                            columns: {
                                                title: ["User Id", "Perfil", "Tipo", ""],
                                                width: [15, 65, 15, 5],
                                                align: ["left", "left", "left", "right"],
                                                search: [true, true, true, false]
                                            },
                                            rows: this.buildGridRows(json),
                                            pagination: true,
                                            actions: [
                                                { action: (e) => this.handleNewUser(e), icon: faPlus, title: "Novo utilizador" }
                                            ]
                                        }
                                    })

                                    this.props.dispatch(Loading(false))
                                })
                                .catch((e) => { this.props.dispatch(Toast("Não foi possível obter os registos", ToastTypes.Danger, false)); console.error(e); })

                        })
                }
            })
            .catch((e) => { this.props.dispatch(Toast("Não foi possível obter os registos", ToastTypes.Danger, false)); console.error(e); })

        fetch("/h1f2d1i1d2b/admin/users", requestOptions)
            .then(response => { if (response.status === 200) return response.json(); return response.then(Promise.reject.bind(Promise)) })
            .then(json => {
                this.setState({ usersNames: json })
            })
            .catch((e) => console.error(e))
    }

    buildGridRows(json) {
        return json.map(row => {
            return {
                id: row.userId,
                columns: [
                    { column: row.userName },
                    { column: row.profileId + " - " + row.profileName },
                    { column: this.state.userIdTypes.find(f => f.key === row.userIdType).value }
                ],
                actions: [
                    { column: faEdit, action: (e) => this.handleEditUser(e, row.userId), tooltip: "Editar" }
                ]
            }
        })
    }

    toggle = () => {
        this.setState(prevState => { return { modal: { ...prevState.modal, isOpen: !prevState.modal.isOpen } } })
    }

    toggleModalCopyPaste = () => {
        this.setState(prevState => { return { modalCopyEnv: { ...prevState.modalCopyEnv, isOpen: !prevState.modalCopyEnv.isOpen } } })
    }

    handleChangeEnvironment(e) {
        e.preventDefault()

        this.setState({ Environment: e.target.value })

        this.getUsers(e.target.value)
    }

    handleCopyUsers() {
        this.setState({ modalCopyEnv: { isOpen: true, from: this.state.Environment, to: (this.state.Environment === 1 ? 2 : 1) } })
    }

    handlePasteUsers() {

        const requestOptions = {
            method: "POST",
            headers: { authorization: "bearer " + this.props.access_token, "Content-Type": "application/json" }
        }

        this.props.dispatch(Loading(true))

        fetch("/h1f2d1i1d2b/admin/users/" + GetParameter("id") + "/" + String(this.state.modalCopyEnv.from) + "/" + String(this.state.modalCopyEnv.to), requestOptions)
            .then(response => {

                if (response.status !== 200) {
                    this.props.dispatch(Toast("Não foi possível gravar!", ToastTypes.Danger, true))
                    return null
                }

                return response.json()
            }).then(j => {
                if (j === null)
                    return

                if (!j) {
                    this.props.dispatch(Toast("Tem de copiar primeiro os perfis!", ToastTypes.error, false))
                    return
                }

                this.setState({
                    modalCopyEnv: {
                        ...this.state.modalCopyEnv,
                        isOpen: false, from: null, to: null
                    }
                })

                this.props.dispatch(Toast("Users copiados", ToastTypes.Success, false))
            })
            .catch((e) => { this.props.dispatch(Toast("Não foi possível copiar!", ToastTypes.Danger, true)); console.error(e) })
    }

    handleNewUser(e) {
        e.preventDefault()

        this.setState({ modal: { isOpen: true, isNew: true, userId: "", profileId: "", userIdType: 1, data: { data: [] }, jsonError: false, lockUserId: false } })
    }

    handleEditUser(e, userId) {
        e.preventDefault()

        const user = this.state.users.find(f => f.userId === userId)

        this.setState({
            modal: {
                isOpen: true, isNew: false,
                userId: user.userId,
                userName: user.userName,
                profileId: user.profileId,
                userIdType: user.userIdType,
                data: JSON.parse(user.data),
                jsonError: false,
                lockUserId: user.userId === 'store' || user.userId === 'office'
            }
        })
    }

    handleChange(e) {

        if (e.error === false) {
            this.setState({
                modal: {
                    ...this.state.modal,
                    jsonError: false,
                    data: e.jsObject
                }
            })

            return
        }

        this.setState({ jsonError: true })
    }

    handleSaveUser() {

        if (this.state.modal.userId === "" || this.state.modal.profileId === "" || this.state.modal.userIdType === "") {
            this.props.dispatch(Toast("Necessário preencher os campos", ToastTypes.Warning, false))
            return
        }

        if ((parseInt(this.state.modal.userIdType) === 1 && !uuidValidate(this.state.modal.userId)) ||
            ((parseInt(this.state.modal.userIdType) === 2 || parseInt(this.state.modal.userIdType) === 3) && isNaN(parseInt(this.state.modal.userId)))) {
            this.props.dispatch(Toast("O UserId não é válido", ToastTypes.Warning, false))
            return
        }

        if (parseInt(this.state.modal.userIdType) === 1 && this.state.usersNames.find(f => f.key.toLowerCase() === this.state.modal.userId.toLowerCase()) === undefined) {
            this.props.dispatch(Toast("O UserId não existe", ToastTypes.Warning, false))
            return
        }

        let auxData = JSON.stringify(this.state.modal.data)
        auxData = auxData.substring(8)
        auxData = auxData.substring(0, auxData.length - 1)

        const data = {
            userId: this.state.modal.userId,
            userName: parseInt(this.state.modal.userIdType) === 1 ? this.state.usersNames.find(f => f.key.toLowerCase() === this.state.modal.userId.toLowerCase()).value : this.state.modal.userId,
            profileId: parseInt(this.state.modal.profileId),
            profileName: parseInt(this.state.modal.profileId) + " - " + this.state.profiles.find(f => f.profileId === parseInt(this.state.modal.profileId)).profileName,
            userIdType: parseInt(this.state.modal.userIdType),
            data: auxData
        }

        const requestOptions = {
            method: "PUT",
            headers: { authorization: "bearer " + this.props.access_token, "Content-Type": "application/json" },
            body: JSON.stringify(data)
        }

        this.props.dispatch(Loading(true))

        fetch("/h1f2d1i1d2b/admin/users/" + GetParameter("id") + "/" + this.state.Environment + "?isnew=" + this.state.modal.isNew, requestOptions)
            .then(response => {
                if (response.status !== 200) {
                    if (response.status === 409)
                        this.props.dispatch(Toast("Já existe um userId na base de dados!", ToastTypes.Danger, true))
                    else
                        this.props.dispatch(Toast("Não foi possível gravar!", ToastTypes.Danger, true))
                    return
                }

                let gRows = [...this.state.table.rows]
                let users = this.state.users
                let index = users.findIndex(f => f.userId === data.userId)

                if (!this.state.modal.isNew) {
                    users[index].userId = data.userId
                    users[index].userName = data.userName
                    users[index].profileId = data.profileId
                    users[index].profileName = data.profileName
                    users[index].userIdType = data.userIdType
                    users[index].data = "{ \"data\": " + data.data + " }"

                    gRows[index].columns[0].column = data.userName
                    gRows[index].columns[1].column = data.profileName
                    gRows[index].columns[2].column = this.state.userIdTypes.find(f => f.key === data.userIdType).value
                }
                else {
                    data.data = "{ \"data\": " + data.data + " }"
                    users.push(data)

                    const newRow = {
                        id: data.userId,
                        columns: [
                            { column: (data.userName === "-1" ? "Todos" : data.userName) },
                            { column: data.profileName },
                            { column: this.state.userIdTypes.find(f => f.key === data.userIdType).value }
                        ],
                        actions: [
                            { column: faEdit, action: (e) => this.handleEditUser(e, data.userId), tooltip: "Editar" }
                        ]
                    }

                    gRows = [...gRows, newRow]
                    gRows.sort((a, b) => a.columns[2].column.localeCompare(b.columns[2].column) || a.columns[0].column.localeCompare(b.columns[0].column))
                }

                this.setState({
                    modal: {
                        ...this.state.modal,
                        isOpen: false, isNew: true, userId: "", profileId: "", userIdType: 1
                    },
                    users: users,
                    table: { ...this.state.table, rows: gRows }
                })

                this.props.dispatch(Toast("Utilizador gravado", ToastTypes.Success, false))
            })
            .catch((e) => { this.props.dispatch(Toast("Não foi possível gravar!", ToastTypes.Danger, true)); console.error(e) })
    }

    handleDeleteUser() {
        if (!window.confirm("O utilizador \"" + this.state.modal.userName + "\" será removido. Confirma?"))
            return;

        const requestOptions = {
            method: "DELETE",
            headers: { authorization: "bearer " + this.props.access_token, "Content-Type": "application/json" }
        }

        this.props.dispatch(Loading(true))

        fetch("/h1f2d1i1d2b/admin/users/" + GetParameter("id") + "/" + this.state.Environment + "/" + this.state.modal.userIdType + "?user=" + this.state.modal.userId, requestOptions)
            .then(response => {
                if (response.status !== 200) {
                    this.props.dispatch(Toast("Não foi possível remover!", ToastTypes.Danger, true))
                    return
                }

                let gRows = this.state.table.rows.filter(f => f.id !== this.state.modal.userId)
                let users = this.state.users.filter(f => f.userId !== this.state.modal.userId)

                this.setState({
                    modal: {
                        ...this.state.modal,
                        isOpen: false, isNew: true, profileId: "", userId: "", userIdType: 1
                    },
                    users: users,
                    table: { ...this.state.table, rows: gRows }
                })

                this.props.dispatch(Toast("Perfil removido", ToastTypes.Success, false))
            })
            .catch((e) => { this.props.dispatch(Toast("Não foi possível remover!", ToastTypes.Danger, true)); console.error(e) })
    }

    render() {

        return (
            <div className="right-panel" style={{ marginLeft: 0, marginTop: 0 }}>
                <div className={"content"} style={{ backgroundColor: "white" }}>
                    <Row style={{ paddingBottom: 10 + "px" }}>
                        <Col>
                            <Button color="primary" style={{ float: "right", marginLeft: "10px" }}
                                onClick={this.handleCopyUsers}><FontAwesomeIcon icon={faCopy} /></Button>

                            <Input type="select" id="ddlEnvironment" value={this.state.Environment} onChange={(e) => this.handleChangeEnvironment(e)} style={{ width: "auto", float: "right" }}>
                                <option value="1">Produção</option>
                                <option value="2">Qualidade</option>
                                <option value="3">Desenvolvimento</option>
                            </Input>
                        </Col>
                    </Row>

                    <McdTable title={this.state.table.title} columns={this.state.table.columns} rows={this.state.table.rows} pagination={this.state.table.pagination}
                        actions={this.state.table.actions} filters={this.state.table.filters} handlerFilter={this.handleFilter} urlSearch={this.props.urlSearch} />

                    <Modal isOpen={this.state.modal.isOpen} toggle={this.toggle}>
                        <ModalHeader toggle={this.toggle}>Utilizador</ModalHeader>
                        <ModalBody>
                            <Row>
                                <Col>
                                    <Label>User Id*</Label>
                                    <Input id="UserId" type="text" value={this.state.modal.userId} autoComplete="off"
                                        disabled={!this.state.modal.isNew || this.state.modal.lockUserId} onChange={(e) => this.setState({ modal: { ...this.state.modal, userId: e.target.value } })} />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Label>Tipo*</Label>
                                    <Input type="select" id="userIdTypes" value={this.state.modal.userIdType} disabled={!this.state.modal.isNew}
                                        onChange={(e) => {
                                            this.setState({
                                                modal: {
                                                    ...this.state.modal,
                                                    userIdType: e.target.value,
                                                    lockUserId: parseInt(e.target.value) >= 4,
                                                    userId: parseInt(e.target.value) === 4 ? 'store' : parseInt(e.target.value) === 5 ? 'office' : this.state.modal.userId
                                                }
                                            })
                                        }}>
                                        {this.state.userIdTypes.map(type => <option key={type.key} value={type.key}>{type.value}</option>)}
                                    </Input>
                                </Col>
                                <Col>
                                    <Label>Perfil*</Label>
                                    <Input type="select" id="profiles" value={this.state.modal.profileId} onChange={(e) => this.setState({ modal: { ...this.state.modal, profileId: e.target.value } })}>
                                        <option value=""></option>
                                        {this.state.profiles.map(store => <option key={store.profileId} value={store.profileId}>{store.profileName}</option>)}
                                    </Input>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Label>Dados auxiliares</Label>
                                    <JSONInput id="a_unique_id" placeholder={this.state.modal.data} locale={locale} style={{ container: { border: "1px solid lightgrey" } }}
                                        theme="light_mitsuketa_tribute" width="100%" height="150px" onChange={this.handleChange} />
                                </Col>
                            </Row>
                        </ModalBody>
                        <ModalFooter style={{ display: "block" }}>
                            <Button color="info" onClick={this.handleDeleteUser} style={{ float: "left", display: !this.state.modal.isNew ? "inline-block" : "none" }}>
                                <FontAwesomeIcon icon={faTrash} /> Remover</Button>
                            <Button color="primary" style={{ float: "right" }} onClick={this.handleSaveUser}><FontAwesomeIcon icon={faSave} /> Gravar</Button>
                        </ModalFooter>
                    </Modal>

                    <Modal isOpen={this.state.modalCopyEnv.isOpen} toggle={this.toggleModalCopyPaste} id="ModalCopyPaste">
                        <ModalHeader toggle={this.toggleModalCopyPaste}>Copiar Users para...</ModalHeader>
                        <ModalBody>
                            <Row>
                                <Col style={{ textAlign: "left" }} xs="12">
                                    <Input type="select" id="ddlPasteEnvironment" onChange={(e) => this.setState({ modalCopyEnv: { ...this.state.modalCopyEnv, to: e.target.value } })}>
                                        <option value="1" disabled={this.state.modalCopyEnv.from === 1}>Produção</option>
                                        <option value="2" disabled={this.state.modalCopyEnv.from === 2}>Qualidade</option>
                                        <option value="3" disabled={this.state.modalCopyEnv.from === 3}>Desenvolvimento</option>
                                    </Input>
                                </Col>
                            </Row>
                        </ModalBody>
                        <ModalFooter style={{ display: "block" }}>
                            <Button color="primary" style={{ float: "right" }}
                                onClick={this.handlePasteUsers}><FontAwesomeIcon icon={faSave} />Copiar</Button>
                        </ModalFooter>
                    </Modal>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return { access_token: state.access_token }
}

export default connect(mapStateToProps)(Users)