import { faUserShield } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { Component } from 'react'
import { Container, Row, Col, Label, Input, Button } from 'reactstrap'
import { GetParameter } from './global/Utils'
import publicIp from "public-ip";

export class Home extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isApp: false,
            clientIp: "0",
            appName: "",
            reqName: "",
            reqEmail: "",
            reqJustification: ""
        }

        this.handleRequest = this.handleRequest.bind(this)
    }

    componentDidMount() {// check if access_token exist
        publicIp.v4().then(r => this.setState({ clientIp: r }))

        const appId = GetParameter("id")
        if (appId === null)
            return;

        fetch("/auth/request-access/" + appId).then(response => { if (response.status !== 200) throw Error(response.statusText); return response.json() })
            .then(json => {
                if (json !== null)
                    this.setState({ isApp: true, appName: json })
            })
    }




    handleRequest() {
        if (this.state.reqName.trim() === "" || this.state.reqEmail === "" || this.state.reqJustification.trim() === "") {
            alert("Tem de preencher todos os campos")
            return
        }

        let mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
        if (!this.state.reqEmail.match(mailformat) || !this.state.reqEmail.toLowerCase().endsWith("@pt.mcd.com")) {
            alert("E-mail inválido")
            return
        }

        const data = {
            clientIp: this.state.clientIp,
            name: this.state.reqName,
            email: this.state.reqEmail,
            justification: this.state.reqJustification
        }

        const requestOptions = {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data)
        }

        fetch("/auth/request-access/" + GetParameter("id"), requestOptions)
            .then(response => {
                if (response.status !== 200) {
                    alert("Não foi possível submeter o pedido! Tente mais tarde")
                    return null
                }

                return response.text()
            })
            .then(json => {
                if (json === null)
                    return;

                alert(json)
                this.setState({ reqName: "", reqEmail: "", reqJustification: "" })
            })
            .catch(e => console.log(e))

    }

    render() {
        if (!this.state.isApp)
            return (<></>)

        return (
            <div style={{ height: 100 + "%" }}>
                <Container style={{ height: 100 + "%" }}>
                    <Row style={{ height: 100 + "%" }} className="align-items-center">
                        <Col xs="12" md={{ size: 8, offset: 2 }} lg={{ size: 6, offset: 3 }}>
                            <div className="jumbotron" style={{ paddingTop: 1.5 + "rem" }}>
                                <div style={{ textAlign: "justify" }}>
                                    O acesso à aplicação <b>{this.state.appName}</b> foi negado. Se era suposto ter acesso, preencha os campos do formulário e submeta-os para avaliação.
                                    </div>
                                <Container style={{ marginTop: 30 + "px" }}>
                                    <Row style={{ marginBottom: 10 + "px" }}>
                                        <Col md="2">
                                            <Label>Nome</Label>
                                        </Col>
                                        <Col md="10">
                                            <Input type="text" maxlenght="100" autoComplete="off" value={this.state.reqName} onChange={e => this.setState({ reqName: e.target.value })} />
                                        </Col>
                                    </Row>
                                    <Row style={{ marginBottom: 10 + "px" }}>
                                        <Col md="2">
                                            <Label>E-mail</Label>
                                        </Col>
                                        <Col md="10">
                                            <Input type="email" maxlenght="100" autoComplete="off" value={this.state.reqEmail} onChange={e => this.setState({ reqEmail: e.target.value })} />
                                        </Col>
                                    </Row>
                                    <Row style={{ marginBottom: 10 + "px" }}>
                                        <Col md="2">
                                            <Label>Motivo</Label>
                                        </Col>
                                        <Col md="10">
                                            <Input type="textarea" maxlenght="100" autoComplete="off" value={this.state.reqJustification} onChange={e => this.setState({ reqJustification: e.target.value })} />
                                        </Col>
                                    </Row>
                                    <Row style={{ marginBottom: 10 + "px", textAlign: "right" }}>
                                        <Col md="12">
                                            <Button color="primary" onClick={this.handleRequest}>
                                                <FontAwesomeIcon icon={faUserShield} /> Submeter
                                            </Button>
                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}


