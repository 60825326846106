import { createStore } from "redux"

const initialState = {
    loading: false, profileId: 0, alert: {
        show: false,
        text: "none",
        color: "primary",
        fixed: false
    }, access_token: null
};

function reducer(state = initialState, action) {

    switch (action.type) {
        case 'LOADING':
            return { ...state, loading: action.payload.show }
        case "ALERT":
            return { ...state, loading: false, alert: action.payload }
        case "UNMOUNT_ALERT":
            return { ...state, alert: { ...state.alert, show: false } }
        case 'ACCESS_TOKEN':
            return { ...state, access_token: action.payload }
        default:
            return state;
    }
}

const store = createStore(reducer)

export default store